define("m12-2020/components/tex-t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var x3dText = Ember.Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    austrittspunktRechts: false,
    translation: Ember.computed('model.firstObject.{transformHelper,viewpointHelper,skalierungsfaktor,schraubenlisteAktualisiert}', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var variable = this.get('variable');
      var x = 0;
      var y = 0;
      var z = 0;
      var cords;
      var item = x3d.get('bauteile').findBy('id', name);
      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var abstandVonDerMasslinie = 0.05;
      var gabelbreite = 0;
      var ueberstand = 0;

      if (x3d.get('gabellager') === true) {
        gabelbreite = Number(this.get('model').objectAt(0).get('bauteile').findBy('id', "gabellager").get('boxsizes').objectAt(0).get('z')) / 10 + Number(bauteile.findBy('id', "gabellager").get('erweiterteeigenschaften').objectAt(0).get('abstandsmass')) / 10;
      }

      if (name === 'auflager' || name === 'traeger' || name === 'auflagernaheEinzellast') {
        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        if (name === 'traeger') {
          if (variable === 'breite') {
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 + 0.1 > bauteile.findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('y') / 10 ? bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 : bauteile.findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('y') / 10 - 0.1;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = x / 2 + hoeheMasskette + abstandVonDerMasslinie + abstandVomBauteil + ' ' + y + ' ' + 0;
            } else {
              cords = x / 2 + ' ' + (y + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie) + ' ' + 0;
            }
          } else if (variable === 'hoehe') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = -(x / 2) + ' ' + y / 2 + ' ' + (-z / 2 - hoeheMasskette - abstandVonDerMasslinie - abstandVomBauteil - gabelbreite);
            } else {
              cords = -(x / 2) - hoeheMasskette - abstandVonDerMasslinie - abstandVomBauteil + ' ' + y / 2 + ' ' + -(z / 2);
            }
          } else if (variable === 'sicherheitsAbstand') {
            var delta = 0;

            if (x3d.get('gabellager') === true) {
              ueberstand = bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('ueberstand') / 10;
              var auflager = bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('x') / 10;
              var gabellager = Number(bauteile.findBy('id', "gabellager").get('boxsizes').objectAt(0).get('x')) / 10;

              if (bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === true) {
                x = auflager / 2 + ueberstand > gabellager / 2 ? x : x - auflager + gabellager;
              }

              var gabelhoehe = x3d.get('hGabellagerEingetragen') === true ? Number(bauteile.findBy('id', 'gabellager').get('boxsizes').objectAt(0).get('y') / 10) : 0.9 * Number(item.get('boxsizes').objectAt(0).get('y')) / 10;

              if (Number(item.get('boxsizes').objectAt(0).get('y')) / 10 - Number(x3d.get('sicherheitsAbstand')) / 10 < gabelhoehe) {
                delta = abstandVomBauteil + hoeheMasskette;
              }
            }

            var dz = 0;

            if (x3d.get('gabellager') === true) {
              dz = Number(bauteile.findBy('id', "gabellager").get('boxsizes').objectAt(0).get('z')) / 10 + Number(bauteile.findBy('id', "gabellager").get('erweiterteeigenschaften').objectAt(0).get('abstandsmass')) / 10;
            }

            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = x / 2 + ' ' + (y - Number(x3d.get('sicherheitsAbstand')) / 20) + ' ' + (z / 2 + hoeheMasskette + abstandVonDerMasslinie + abstandVomBauteil + delta + dz);
            } else {
              cords = x / 2 + hoeheMasskette + abstandVonDerMasslinie + abstandVomBauteil + delta + ' ' + (y - Number(x3d.get('sicherheitsAbstand')) / 20) + ' ' + (z / 2 + dz);
            }
          }
        }

        if (name === 'auflager') {
          y = 0;

          if (x3d.get('gabellager') === true) {
            y = y - Number(this.get('model').objectAt(0).get('bauteile').findBy('id', "traeger").get('boxsizes').objectAt(0).get('y')) / 20;
          }

          z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 10;

          if (variable === 'breite') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              if (bauteile.findBy('id', 'auflager').get('erweiterteeigenschaften').objectAt(0).get('istendauflager') === true) {
                ueberstand = Number(item.get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) / 10;

                if (x3d.get('gabellager') === true) {
                  ueberstand = x / 2 + ueberstand > Number(bauteile.findBy('id', "gabellager").get('boxsizes').objectAt(0).get('x')) / 20 ? ueberstand : Number(bauteile.findBy('id', "gabellager").get('boxsizes').objectAt(0).get('x')) / 20 - x / 2;
                }
              } else {
                x = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x') / 10;
              }

              cords = -ueberstand - x / 2 - hoeheMasskette - abstandVonDerMasslinie - abstandVomBauteil + ' ' + y + ' ' + 0;
            } else {
              cords = -x / 2 + ' ' + (y - hoeheMasskette + abstandVonDerMasslinie - abstandVomBauteil) + ' ' + 0;
            }
          } else if (variable === 'laenge') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = 0 + ' ' + y + ' ' + (z / 2 + hoeheMasskette - abstandVonDerMasslinie + abstandVomBauteil + gabelbreite);
            } else {
              cords = 0 + ' ' + (y - hoeheMasskette + abstandVonDerMasslinie - abstandVomBauteil) + ' ' + (z / 2 + gabelbreite);
            }
          } else if (variable === 'ueberstand') {
            x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 10) - bauteile.findBy('id', name).get('erweiterteeigenschaften').objectAt(0).get('ueberstand') / 10;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = x / 2 + ' ' + y + ' ' + (z / 2 + hoeheMasskette - abstandVonDerMasslinie + abstandVomBauteil + gabelbreite);
            } else {
              cords = x / 2 + ' ' + (y - hoeheMasskette + abstandVonDerMasslinie - abstandVomBauteil) + ' ' + (z / 2 + gabelbreite);
            }
          }
        }

        if (name === 'auflagernaheEinzellast') {
          if (variable === 'breite') {
            z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;
            x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 10) - bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = x / 2 + ' ' + 0 + ' ' + (-z - hoeheMasskette - abstandVonDerMasslinie - abstandVomBauteil - gabelbreite);
            } else {
              cords = x / 2 + ' ' + (+hoeheMasskette + abstandVonDerMasslinie + abstandVomBauteil) + ' ' + -z;
            }
          }
        }
      }

      if (name === 'lastenPfeil') {
        cords = 0 + ' ' + -1.2 + ' 0';
      }

      return cords;
    }),
    rotation: Ember.computed('model.firstObject.{transformHelper,viewpointHelper}', function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var variable = this.get('variable');
      var values;
      var w90Grad = 89 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;

      if (name === 'traeger') {
        if (variable === 'breite') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 1 1 ' + w240Grad;
          } else {
            values = '0 1 0 ' + -w90Grad;
          }
        } else if (variable === 'hoehe') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 1 -1 ' + w240Grad;
          } else {
            values = '0 0 1 ' + w90Grad;
          }
        } else if (variable === 'sicherheitsAbstand') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '-1 1 1 ' + w240Grad;
          } else {
            values = '0 0 1 ' + -w90Grad;
          }
        }
      }

      if (name === 'auflager') {
        if (variable === 'breite') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 -1 -1 ' + w240Grad;
          } else {
            values = '0 1 0 ' + -w90Grad;
          }
        } else if (variable === 'laenge') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 0 0 ' + -w90Grad;
          } else {
            values = '0 0 0 0';
          }
        } else if (variable === 'ueberstand') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 0 0 ' + -w90Grad;
          } else {
            values = '0 0 0 0';
          }
        }
      }

      if (name === 'auflagernaheEinzellast') {
        if (variable === 'breite') {
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '1 0 0 ' + -w90Grad;
          } else {
            values = '0 1 0 ' + 0;
          }
        }
      }

      if (name.substring(0, 8) === 'schraube') {
        if (this.get('typ') === 'xPos') {
          if (this.austrittspunktRechts) {
            values = '0 0 1 -1.5707';
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '0 0 0 0';
            } else {
              values = '1 0 0 -1.5707';
            }
          }
        }

        if (this.get('typ') === 'zPos') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 1 0 -1.5707';
          } else {
            values = '1 1 1 4.2';
          }
        }
      }

      if (name === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }),
    wert: Ember.computed('model.firstObject.{transformHelper,viewpointHelper,istHt,schraubenGekreuzt,schraubenlisteAktualisiert}', function () {
      var self = this;
      var name = this.get('name');
      var variable = this.get('variable');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 'test';
      var item = x3d.get('bauteile').findBy('id', name);

      if (name === 'auflager') {
        if (variable === 'laenge' && x3d.get('aLaengeEingetragen')) {
          value = item.get('boxsizes').objectAt(0).get('x');
        } else if (variable === 'breite' && x3d.get('aBreiteEingetragen')) {
          value = item.get('boxsizes').objectAt(0).get('z');
        } else if (variable === 'ueberstand' && Number(item.get('erweiterteeigenschaften').objectAt(0).get('ueberstand')) !== 0 && x3d.get('ueberstandEingetragen')) {
          value = item.get('erweiterteeigenschaften').objectAt(0).get('ueberstand');
        }
      }

      if (name === 'traeger') {
        if (variable === 'hoehe' && x3d.get('htHoeheEingetragen')) {
          value = item.get('boxsizes').objectAt(0).get('y');
        } else if (variable === 'breite' && x3d.get('htBreiteEingetragen')) {
          value = item.get('boxsizes').objectAt(0).get('z');
        } else if (variable === 'sicherheitsAbstand' && x3d.get('sicherheitsAbstandEingetragen')) {
          value = x3d.get('sicherheitsAbstand');
        }
      }

      if (name === 'auflagernaheEinzellast') {
        if (variable === 'breite' && x3d.get('abstandsmassEingetragen')) {
          value = item.get('translations').objectAt(0).get('x');
        }
      }

      if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {
        if (this.get('typ') === 'xPos' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          var xKopfPos = this.getSchraubeKopfPos(name, 'x');
          value = Math.abs((xKopfPos * 10).toFixed(1));
          value = parseFloat(value).toFixed(1);
        }

        if (this.get('typ') === 'zPos' && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
          switch (this.get('variable')) {
            case 'randAbstand1':
              value = x3d.get('randAbstand1');
              value = parseFloat(value).toFixed(1);
              break;

            case 'randAbstand2':
              value = x3d.get('randAbstand2');
              value = parseFloat(value).toFixed(1);
              break;

            case 'schraubenAbstand1':
              value = x3d.get('schraubenAbstand1');
              value = parseFloat(value).toFixed(1);
              break;

            case 'schraubenAbstand2':
              value = x3d.get('schraubenAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
          }
        }
      }

      if (name === 'lastenPfeil') {
        value = x3d.get('bemessungslast');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      return value;
    }),
    showIt: Ember.computed('wert', function () {
      if (this.get('wert') !== 'test' && isNaN(this.get('wert')) === false) {
        return "true";
      } else {
        return "false";
      }
    }),
    diffusecolor: Ember.computed('model.firstObject.{htbreiteAktiv,hthoeheAktiv,abreiteAktiv,alaengeAktiv,sicherheitsAbstandAktiv,vWinkelAktiv,bemessungslastAktiv,ueberstandAktiv,spaltmassAktiv}', function () {
      var name = this.get('name');
      var typ = this.get('variable');
      var x3d = this.get('model').objectAt(0);
      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'traeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'traeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('abreiteAktiv') === true && name === 'auflager' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('alaengeAktiv') === true && name === 'auflager' && typ === 'laenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('bemessungslastAktiv') === true && name === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('ueberstandAktiv') === true && name === 'auflager' && typ === 'ueberstand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('sicherheitsAbstandAktiv') === true && name === 'traeger' && typ === 'sicherheitsAbstand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('spaltmassAktiv') === true && name === 'auflagernaheEinzellast' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }),
    textgroesse: Ember.computed('model.firstObject.{skalierungsfaktor,transformHelper}', function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }),
    getSchraubeKopfPos: function (name, achse) {
      var value = 0;
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);
      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;
      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var xKopfPos;

      if (xVerschiebung > 0) {
        var vwinkel = x3d.get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);

        if (Number(xKopfPos) > Number(x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    }
  });
  var _default = x3dText;
  _exports.default = _default;
});